import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import { DenormalizedProduct, Product } from '@packages/types'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'

import { trpc } from 'common/hooks/trpc'

import useProductService from './../hooks/useProductService'

export interface DeleteProductModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  product: Product | DenormalizedProduct
  onClose: () => void
  onDeleted?: () => void
}

const DeleteProductModal = ({ product, onClose, onDeleted, ...modalProps }: DeleteProductModalProps) => {
  const productService = useProductService()
  const { openGenericErrorToast, openToast } = useToast()
  const queryClient = useQueryClient()

  const { mutate: deleteProduct, isLoading } = trpc.product.delete.useMutation({
    onSuccess: () => {
      openToast(`Product "${product!.name}" was successfully deleted!`, ToastType.success)
      queryClient.invalidateQueries(productService.fetchAll.queryKeys)
      onDeleted?.()
    },
    onError: () => openGenericErrorToast(`Product "${product.name}" has not been deleted.`),
    onSettled: () => onClose(),
  })

  return (
    <Modal onBackdropClick={onClose} {...modalProps}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Delete product</Modal.Title>
      <Modal.Details className="!pb-0">
        Are you sure you want to delete <em>{product.name}</em>? This means this product's customizer won't be available
        in online stores and will be deleted in your ecommerce platform.
      </Modal.Details>
      <Modal.Details>This action cannot be undone.</Modal.Details>

      <Modal.Actions>
        <Button variant="default" className="px-4" onClick={onClose}>
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          variant="primary"
          className="px-4"
          aria-label="confirm delete"
          onClick={() => deleteProduct(product.id)}
        >
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteProductModal
