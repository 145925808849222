import { Alert } from '@packages/sk8/alert'
import { Button } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { Tag } from '@packages/sk8/tag'
import { PaymentStrategy, Subscription, SubscriptionStatus } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import { format, formatDistanceToNow, isPast } from 'date-fns'
import React from 'react'

import AddOnlineStoreButton from 'cms/onlineStores/components/AddOnlineStoreButton'
import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import { storeIsInstalled, subscriptionHasWhiteLabel } from 'cms/subscription/utils'
import StarsIcon from 'icons/custom/reward-stars-3-with-blue-gradient.svg'
import classMerge from 'utils/classMerge'

import PlanPrice from '../../PlanPrice'
import SubscriptionCardSkeleton from '../skeletons/SubscriptionSkeleton'
import SubscriptionCardActions from './SubscriptionCardActions'
import SubscriptionCardProductLimit from './SubscriptionCardProductLimit'

interface SubscriptionCardProps {
  subscription?: Subscription
  onPlanChange: () => void
  onContinueFreeTrial: () => void
}

const status: Record<SubscriptionStatus, { class: string; label: string }> = {
  [SubscriptionStatus.Active]: { class: 'text-tertiary-green-500 bg-tertiary-green-75', label: 'Active' },
  [SubscriptionStatus.FreeTrial]: { class: 'text-primary-900 bg-primary-75', label: 'Free trial' },
  [SubscriptionStatus.Future]: { class: 'text-primary-900 bg-primary-75', label: 'Future' },
  [SubscriptionStatus.Canceled]: { class: 'text-tertiary-red-500 bg-tertiary-red-75', label: 'Cancelled' },
  [SubscriptionStatus.PaymentError]: { class: 'text-tertiary-red-500 bg-tertiary-red-75', label: 'Payment error' },
  [SubscriptionStatus.RequestCancel]: {
    class: 'text-warning-dark bg-warning-light',
    label: 'Request cancel',
  },
  [SubscriptionStatus.Expired]: { class: 'text-neutral-900 bg-neutral-75', label: 'Expired' },
}

const formatDate = (date: Date) => format(date, 'MMM d, yyyy')

const getActiveDiscounts = (subscription: Subscription) => {
  if (subscription.paymentStrategy !== PaymentStrategy.Stripe) return []

  return subscription
    .stripeDiscounts!.filter(
      stripeDiscount => stripeDiscount.coupon.duration === 'forever' || !isPast(new Date(stripeDiscount.end * 1000))
    )
    .map(stripeDiscount => {
      return {
        duration: stripeDiscount.coupon.duration,
        end: stripeDiscount.end * 1000,
        type: stripeDiscount.coupon.amount_off != null ? 'fixed' : 'percent',
        value:
          stripeDiscount.coupon.amount_off != null
            ? stripeDiscount.coupon.amount_off / 100
            : stripeDiscount.coupon.percent_off / 100,
      }
    })
}

const SubscriptionCard = ({ subscription, onPlanChange, onContinueFreeTrial }: SubscriptionCardProps) => {
  const onlineStoreService = useOnlineStoreService()
  const onlineStoreQuery = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll)
  if (!subscription || !onlineStoreQuery.data || onlineStoreQuery.isLoading) return <SubscriptionCardSkeleton />

  const subscriptionStatus =
    subscription.status === SubscriptionStatus.FreeTrial && isPast(new Date(subscription.freeTrialEndsOn))
      ? SubscriptionStatus.Expired
      : subscription.status

  const hasWhiteLabel = subscriptionHasWhiteLabel(subscription)
  const isInstalled = storeIsInstalled(subscription, onlineStoreQuery.data)
  const stripeFreeTrialActivated =
    subscription.paymentStrategy === PaymentStrategy.Stripe &&
    subscription.status === SubscriptionStatus.FreeTrial &&
    !!subscription.stripePaymentMethodId

  const discounts = getActiveDiscounts(subscription)

  const totalDiscount = discounts.reduce((totalDiscount, discount) => {
    if (discount.type === 'fixed') return discount.value + totalDiscount
    if (discount.type === 'percent') return discount.value * subscription.plan.price.recurringAmount + totalDiscount

    return totalDiscount
  }, 0)

  return (
    <>
      {!isInstalled &&
        (subscription.paymentStrategy === PaymentStrategy.Shopify ||
          subscription.paymentStrategy === PaymentStrategy.None) && (
          <div className="mb-8">
            <Alert variant="warning" className="w-full">
              <Alert.Body>
                <Alert.Title>Install Kickflip app</Alert.Title>
                <Alert.Details>
                  Kickflip needs to be installed on an ecommerce to activate your subscription.
                </Alert.Details>
              </Alert.Body>

              <Alert.Actions>
                <AddOnlineStoreButton>Install app</AddOnlineStoreButton>
              </Alert.Actions>
            </Alert>
          </div>
        )}
      <Card className="flex flex-1 flex-col">
        <Card.Section className="space-x-6">
          <div className="flex flex-wrap space-y-4 justify-between">
            <span className="w-full text-neutral-400 text-xs leading-5">Plan</span>
            <span aria-label="plan name" className="text-neutral-900 text-sm">
              {subscriptionStatus === SubscriptionStatus.FreeTrial &&
              !stripeFreeTrialActivated &&
              !subscription.plan.custom
                ? 'Free trial'
                : subscription.plan.name}
            </span>
          </div>

          {subscriptionStatus !== SubscriptionStatus.FreeTrial && (
            <>
              <div className="border-r border-solid border-neutral-100" />
              <div className="flex flex-col flex-1 space-y-4 grow">
                <span className="text-neutral-400 text-xs leading-5">Billed</span>
                <span className="text-neutral-900 text-sm">Monthly</span>
              </div>
              <div className="border-r border-solid border-neutral-100" />
              <div className="flex flex-col items-end grow text-right">
                <PlanPrice
                  plan={subscription.plan}
                  addonsSubscribed={subscription.addons}
                  variant="small"
                  discount={totalDiscount}
                />
              </div>
            </>
          )}
        </Card.Section>
        {discounts.length > 0 && (
          <>
            <Card.Separator />
            <Card.Section>
              <div className="flex flex-col flex-grow space-y-4">
                <div className="flex flex-grow flex-wrap justify-between">
                  <span className="w-full text-neutral-400 text-xs leading-5 mb-[2px]">Discounts</span>
                </div>
                {discounts.map(discount => {
                  return (
                    <div key={discount.type} className="flex flex-grow flex-wrap justify-between">
                      <div className="flex space-x-1">
                        {discount.type === 'percent' && (
                          <div data-testid="discount-amount" className="text-tertiary-green-500">
                            {discount.value * 100}%
                          </div>
                        )}
                        {discount.type === 'fixed' && (
                          <div data-testid="discount-amount" className="text-tertiary-green-500">
                            ${discount.value}
                          </div>
                        )}
                      </div>
                      {discount.duration === 'forever' ? (
                        <div data-testid="discount-end">forever</div>
                      ) : (
                        <div data-testid="discount-end">until {formatDate(new Date(discount.end))}</div>
                      )}
                    </div>
                  )
                })}
              </div>
            </Card.Section>
          </>
        )}
        <Card.Separator />
        {(subscriptionStatus === SubscriptionStatus.Active || stripeFreeTrialActivated) && (
          <>
            <Card.Section className="space-x-6">
              <div className="flex flex-col flex-grow space-y-4">
                <span className="w-full text-neutral-400 text-xs leading-5 mb-[2px]">Plan details</span>
                <span className="text-neutral-900 text-sm">Product limit</span>
                <span className="text-neutral-900 text-sm">Transfer & storage</span>
                <span className="text-neutral-900 text-sm">White label</span>
              </div>
              <div className="flex flex-col space-y-4 items-end justify-end">
                {![PaymentStrategy.None, PaymentStrategy.Manual].includes(subscription.paymentStrategy) && (
                  <Button
                    icon={<StarsIcon className="w-4 h-4" />}
                    className="bg-gradient-blue bg-clip-text text-transparent active:text-transparent"
                    onClick={onPlanChange}
                    small
                  >
                    Upgrade
                  </Button>
                )}
                <SubscriptionCardProductLimit subscription={subscription} />
                <span className="text-neutral-900 text-sm">Unlimited</span>
                <span className="text-neutral-900 text-sm">{hasWhiteLabel ? 'Included' : 'Not included'}</span>
              </div>
            </Card.Section>
            <Card.Separator />
          </>
        )}

        <Card.Section className="space-x-6">
          <div className={classMerge('flex flex-1 flex-wrap space-y-4 items-start', {})}>
            <span className="text-neutral-400 text-xs leading-5 w-full">Status</span>
            <Tag className={classMerge(status[subscriptionStatus].class, 'mr-2')} aria-label="subscription status tag">
              {status[subscriptionStatus].label}
            </Tag>
            {subscriptionStatus === SubscriptionStatus.FreeTrial && stripeFreeTrialActivated && (
              <span className="text-neutral-400 text-xs leading-5">
                (will be active on {formatDate(new Date(subscription.freeTrialEndsOn))})
              </span>
            )}
            {subscriptionStatus === SubscriptionStatus.FreeTrial && !stripeFreeTrialActivated && (
              <span className="text-neutral-400 text-xs leading-5">
                (ends in {formatDistanceToNow(new Date(subscription.freeTrialEndsOn))})
              </span>
            )}
            {subscriptionStatus === SubscriptionStatus.Expired && (
              <span className="text-neutral-400 text-xs leading-5">
                (free trial ended {formatDistanceToNow(new Date(subscription.freeTrialEndsOn))} ago)
              </span>
            )}
          </div>
          <div className="border-r border-solid border-neutral-100" />
          <div className="flex flex-col space-y-4 items-end">
            <span className="text-neutral-400 text-xs leading-5">Subscription date</span>
            <span className="text-neutral-900 text-sm">{formatDate(new Date(subscription.createdAt))}</span>
          </div>
        </Card.Section>
        <Card.Separator />

        {subscription.paymentStrategy !== PaymentStrategy.Manual && (
          <SubscriptionCardActions
            subscription={subscription}
            isInstalled={isInstalled}
            onPlanChange={onPlanChange}
            onContinueFreeTrial={onContinueFreeTrial}
          />
        )}
      </Card>
    </>
  )
}

export default SubscriptionCard
