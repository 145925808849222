import { Input } from '@packages/sk8/input'
import { Price } from '@packages/sk8/price'
import { Table } from '@packages/sk8/table'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { useContext } from 'react'

import QuoteContext from 'cms/quotes/QuoteContext'
import classMerge from 'utils/classMerge'

import { QuoteFormValues } from './Quote'

export interface QuoteTableEditableCellProps {
  value?: number
  className?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  type: 'price' | 'quantity' | 'percentage'
  id?: string
  name?: string
}

const QuoteTableEditableCell = ({
  value,
  onChange,
  className,
  type,
  id,
  name,
  ...cellProps
}: QuoteTableEditableCellProps) => {
  const { quote, isEditing, currenciesInputProps } = useContext(QuoteContext)
  const { errors } = useFormikContext<QuoteFormValues>()

  if (!isEditing) {
    if (type === 'percentage') {
      return (
        <Table.Cell className={classMerge('py-3 pl-2', className)} {...cellProps}>
          {value}%
        </Table.Cell>
      )
    }

    if (type === 'quantity') {
      return (
        <Table.Cell className={classMerge('py-3 pl-2', className)} {...cellProps}>
          {value}
        </Table.Cell>
      )
    }

    return (
      <Table.Cell className={classMerge('py-3 pl-2', className)} {...cellProps}>
        {value === 0 || !value ? (
          <span aria-label="empty base price" className="font-medium text-sm pl-1 text-neutral-300">
            &mdash;
          </span>
        ) : (
          <Price amount={value} currency={quote?.currency ?? 'USD'} className="font-medium text-sm" />
        )}
      </Table.Cell>
    )
  }

  const unsupportedKeys = ['-', 'e']

  return (
    <Table.Cell className={classMerge('pl-0 py-[6px]', className)}>
      <Input
        className="pl-2"
        hasError={name ? !!get(errors, name as string) : false}
        type="number"
        id={id}
        name={name}
        aria-label={name}
        value={value ?? 0}
        onChange={onChange}
        onKeyDown={e => {
          if (unsupportedKeys.includes(e.key)) e.preventDefault()
        }}
        {...(type === 'price' && currenciesInputProps)}
        {...(type === 'percentage' && { rightAddon: '%' })}
      />
    </Table.Cell>
  )
}
export default QuoteTableEditableCell
