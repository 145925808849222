import { CheckboxFilter } from '@packages/sk8/filter'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import withFlag from 'common/users/components/withFlag'

type OnlineStoresFilterProps = {
  selectedOnlineStores: string[] | undefined
  toggleOnlineStore: (name: string) => void
  clearFilters: () => void
}

const OnlineStoresFilter = ({ selectedOnlineStores, toggleOnlineStore, clearFilters }: OnlineStoresFilterProps) => {
  const onlineStoreService = useOnlineStoreService()
  const { data: onlineStores } = useQuery(onlineStoreService.fetchAll.queryKeys, () => onlineStoreService.fetchAll())

  if (!onlineStores?.length) return null

  return (
    <CheckboxFilter
      name="Store"
      filters={onlineStores.map(onlineStore => ({ name: onlineStore.id, text: onlineStore.name }))}
      activeFilters={selectedOnlineStores || []}
      toggleFilter={toggleOnlineStore}
      clearFilter={clearFilters}
    />
  )
}

export default withFlag({
  Component: OnlineStoresFilter,
  feature: 'quote_phase_1',
})
