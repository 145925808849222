import { Variant, InventoryItem, InventoryLevel } from '@packages/types'

export enum UpdateTypes {
  adjust = 'adjust',
  set = 'set',
}

export interface InventoryItemWithStock extends Omit<InventoryItem, 'inventoryLevelByLocationId'> {
  stock: number | null
  committed: number
}

export type Group = {
  productName: string
  questionNames: string
  key: string
  variantIds: string[]
}

export type CombinationFilters = {
  _id: { productId: string; questionIds: string[] }
  productName: string
  questionNames: string
}[]

export interface PaginatedVariants {
  pagination: {
    collectionSize: number
    lastIndex: number
    resultSize: number
    sortKey: string
    sortOrder: 'ascending' | 'descending'
  }
  results: Variant[]
}

export type VariantIdsByCombination = {
  _id: { productId: string; questionIds: string[] }
  ids: string[]
}[]

export type InventorySortKeys = 'variant' | 'stock' | 'sku'

export { InventoryItem, InventoryLevel, Variant }
