import { EntityType, Group, Part, Question } from '@packages/types'
import { useTransition, animated, UseTransitionProps } from '@react-spring/web'
import { Location } from 'history'
import React, { useRef } from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'

import * as coreUtils from 'builder/build/core/utils'
import GroupPanelFactory from 'builder/build/groups/components/GroupPanelFactory'
import useCustomizerNavigation from 'builder/build/navigation/useCustomizerNavigation'
import * as partsSelectors from 'builder/build/parts/selectors'
import QuestionPanelFactory from 'builder/build/questions/components/QuestionPanelFactory'
import SubmitActionRightPanel from 'builder/build/submit/components/SubmitActionRightPanel'
import { useSelector } from 'cms/hooks'

import { entitiesSelector } from '../selectors'

const getPanels = (location: Location<null>, entity: Group | Question | undefined, parts: Part[]) => {
  if (location.pathname === '/submit-action') return [{ component: <SubmitActionRightPanel />, key: 'submit-action' }]

  if (location.pathname === '/' || !entity) return []

  if (entity.entityType === EntityType.Group) {
    return [{ key: entity.id, type: EntityType.Group, component: <GroupPanelFactory group={entity} /> }]
  }

  if (entity.entityType === EntityType.Question) {
    const part = coreUtils.getPartFromQuestion(entity.id, parts)
    return [{ key: part ? part.id : entity.id, component: <QuestionPanelFactory part={part} question={entity} /> }]
  }

  return []
}

const BuilderRightPanel = () => {
  const location = useLocation<null>()
  const match = useRouteMatch<{ id: string }>()
  const isInitialAnimation = useRef(true)
  const entityStack = useSelector(state => entitiesSelector(state, [match.params.id]))
  const entity = entityStack[0] as Group | Question | undefined
  const parts = useSelector(partsSelectors.partsAsArraySelector)
  const panels = getPanels(location, entity, parts)

  useCustomizerNavigation()

  const animation: UseTransitionProps<(typeof panels)[number]> = {
    keys: panels.map(panel => panel.key),
    initial: { transform: 'translate3d(0px, 0, 0)' },
    ...(isInitialAnimation.current ? {} : { from: { transform: `translate3d(342px, 0, 0)` } }),
    enter: () => async next => {
      await next({ transform: 'translate3d(0px, 0, 0)' })
      isInitialAnimation.current = false
    },
    leave: () => async next => {
      if (document.activeElement?.nodeName === 'INPUT') {
        const inputElement = document.activeElement as HTMLInputElement
        inputElement.blur()
      }

      await next({ transform: `translate3d(80px, 0, 0)` })
    },
    immediate: CURRENT_ENV === 'test',
  }

  const transitions = useTransition(panels, animation)

  if (panels.length === 0)
    return (
      <div className="builder__panels-containers-right">
        <div className="w-full h-full">
          <div className="panel panel--right w-full">
            <div className="px-6 mt-48">
              <div className="font-medium mb-2">Question settings</div>
              <div className="text-neutral-600 mb-4">
                You will manage your question settings in this panel once you create your first question.
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div className="builder__panels-containers-right">
      {transitions((style, item, transitionState) => (
        <animated.div
          aria-label=""
          key={transitionState.key}
          className="fixed right-0 h-[calc(100%-54px)]"
          style={{ width: '252px', ...style }}
        >
          {item.component}
        </animated.div>
      ))}
    </div>
  )
}

export default BuilderRightPanel
