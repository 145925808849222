import React, { useEffect } from 'react'

import ArrowDown from '../../icons/bold/01-Interface Essential/40-Keyboard/keyboard-arrow-down.svg'
import ArrowUp from '../../icons/bold/01-Interface Essential/40-Keyboard/keyboard-arrow-up.svg'
import SortRowsIcon from '../../icons/custom/sort-rows.svg'
import classMerge from '../../utils/classMerge'
import Button from '../button/Button'
import RadioGroup from '../input/RadioGroup'
import Popover from '../popover/Popover'
import usePopover from '../popover/usePopover'

export type SortField = {
  name: string
  text: string
  type: 'alpha' | 'date' | 'num'
  defaultOrder: -1 | 1
  className?: string
  iconClassName?: string
}

export interface SortFilterProps {
  name: string
  sortKey?: string | null
  setSortKey: (sort: string) => void
  sortOrder?: number | null
  setSortOrder: (order: -1 | 1) => void
  fields: SortField[]
}

const SortFilter = ({ name, sortKey, setSortKey, sortOrder, setSortOrder, fields }: SortFilterProps) => {
  const popover = usePopover()

  const activeField = fields.find(field => field.name == sortKey)

  useEffect(() => {
    if (activeField && activeField.defaultOrder !== sortOrder) setSortOrder(activeField.defaultOrder)
  }, [sortKey])

  return (
    <div
      className={classMerge(
        'border-neutral-100 border shadow-xs h-7 rounded-xl font-medium flex items-center overflow-hidden focus-within:border-primary-200 focus-within:ring',
        {
          'border-dashed': !sortKey,
        }
      )}
    >
      <Button
        variant="text"
        className="!text-xs mx-1.5 text-neutral-400"
        icon={<SortRowsIcon className="w-3 h-3 fill-current" />}
        {...popover.referenceProps}
      >
        {name}
      </Button>

      <Popover className="w-[149px]" isOpen={popover.isOpen} {...popover.floatingProps}>
        <div className="p-4 pb-0 mb-1 font-medium">Sort by</div>
        <div className={classMerge('flex flex-wrap px-4 py-2', { 'pb-4': !sortKey })}>
          <RadioGroup
            name="sortKey"
            options={fields.map(field => ({
              label: field.text,
              id: field.name,
              checked: field.name === sortKey,
              onChange: () => setSortKey(field.name),
            }))}
          />
        </div>
        {sortKey && (
          <div className="border-t border-t-neutral-75 p-4 pt-2">
            {activeField?.type === 'alpha' && (
              <>
                <Button
                  className={classMerge('w-full', { 'text-primary-600 hover:text-primary-600': sortOrder === 1 })}
                  variant="text"
                  icon={<ArrowUp className="fill-current h-3" />}
                  onClick={() => setSortOrder(1)}
                >
                  A to Z
                </Button>
                <Button
                  className={classMerge('w-full', { 'text-primary-600 hover:text-primary-600': sortOrder === -1 })}
                  variant="text"
                  icon={<ArrowDown className="fill-current h-3" />}
                  onClick={() => setSortOrder(-1)}
                >
                  Z to A
                </Button>
              </>
            )}
            {activeField?.type === 'date' && (
              <>
                <Button
                  className={classMerge('w-full', { 'text-primary-600 hover:text-primary-600': sortOrder === -1 })}
                  variant="text"
                  icon={<ArrowUp className="fill-current h-3" />}
                  onClick={() => setSortOrder(-1)}
                >
                  Newest to Oldest
                </Button>
                <Button
                  className={classMerge('w-full', { 'text-primary-600 hover:text-primary-600': sortOrder === 1 })}
                  variant="text"
                  icon={<ArrowDown className="fill-current h-3" />}
                  onClick={() => setSortOrder(1)}
                >
                  Oldest to Newest
                </Button>
              </>
            )}
          </div>
        )}
      </Popover>
    </div>
  )
}

export default SortFilter
