import { Card } from '@packages/sk8/card'
import { Tag } from '@packages/sk8/tag'
import React, { useContext } from 'react'

import { isCustomStore, isShopify } from 'cms/onlineStores/utils'
import QuoteContext from 'cms/quotes/QuoteContext'
import withFlag from 'common/users/components/withFlag'

import QuotesLoadingSkeleton from './QuoteLoadingSkeleton'

const QuoteStoreCard = () => {
  const { quote, isLoadingQuotes } = useContext(QuoteContext)
  return (
    <Card>
      <Card.Section className="flex-row items-center w-[263px] space-x-2 justify-between">
        {!isLoadingQuotes && quote ? (
          <>
            <div className="flex flex-col space-y-1 overflow-hidden ">
              <span className="text-sm font-medium text-neutral-900">{quote.store.name}</span>
              <span className="text-xs font-medium text-neutral-400 text-ellipsis overflow-hidden whitespace-nowrap">
                {isShopify(quote.store) || isCustomStore(quote.store) ? quote.store.domain : quote.store.url}
              </span>
            </div>
            <div className="basis-8 shrink-0 ml-auto">
              <Tag className="bg-neutral-100 text-neutral-600 text-[10px] mt-0.5 ml-1">{quote.store.currency}</Tag>
            </div>
          </>
        ) : (
          <QuotesLoadingSkeleton />
        )}
      </Card.Section>
    </Card>
  )
}

export default withFlag({
  feature: 'quote_phase_2',
  Component: QuoteStoreCard,
})
