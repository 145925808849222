import {
  CheckboxFilter,
  ClearFiltersButton,
  FilterList,
  NumberFilter,
  NumberFilterValue,
  SearchFilter,
} from '@packages/sk8/filter'
import { uniq, without } from 'lodash'
import React from 'react'

import UnsavedChangesTooltip from 'common/components/filters/UnsavedChangesTooltip'
import type { CombinationFilters } from 'common/variants/types/variant'

import { getCombinationFilters, getHasNoAppliedFilters } from '../utils'
import type { InventoryDataTable } from './../types/dataTable'
import type { InventoryFormikContext } from './../types/form'

export interface FiltersProps {
  formik: InventoryFormikContext
  dataTable: InventoryDataTable
  combinationFiltersData: CombinationFilters
  clearAllFilters: () => void
  children?: React.ReactNode
}

const Filters = ({ formik, dataTable, combinationFiltersData, clearAllFilters, children }: FiltersProps) => {
  const combinationFilters = getCombinationFilters(combinationFiltersData)
  const hasNoAppliedFilters = getHasNoAppliedFilters(dataTable)

  return (
    <>
      <FilterList>
        {children == null ? null : children}
        <UnsavedChangesTooltip disabled={!formik.dirty}>
          <SearchFilter
            name="Search"
            setSearch={dataTable.setSearch}
            search={dataTable.search}
            disabled={formik.dirty}
          />
        </UnsavedChangesTooltip>
        <UnsavedChangesTooltip disabled={!formik.dirty}>
          <CheckboxFilter
            activeFilters={dataTable.combinations}
            filters={combinationFilters}
            toggleFilter={name => dataTable.toggleFilter('combinations', name)}
            activateFilters={names => dataTable.setFilter('combinations', uniq([...dataTable.combinations, ...names]))}
            deactivateFilters={names => {
              dataTable.setFilter('combinations', without(dataTable.combinations, ...names))
            }}
            clearFilter={() => dataTable.clearFilters('combinations')}
            name="Product"
            searchable
            selectableGroups
            disabled={formik.dirty}
          />
        </UnsavedChangesTooltip>
        <UnsavedChangesTooltip disabled={!formik.dirty}>
          <NumberFilter
            activeFilter={dataTable.committed as NumberFilterValue}
            setFilter={filter => dataTable.setFilter('committed', filter)}
            clearFilter={() => dataTable.clearFilters('committed')}
            name="Committed"
            disabled={formik.dirty}
          />
        </UnsavedChangesTooltip>
        <UnsavedChangesTooltip disabled={!formik.dirty}>
          <NumberFilter
            activeFilter={dataTable.available as NumberFilterValue}
            setFilter={filter => dataTable.setFilter('available', filter)}
            clearFilter={() => dataTable.clearFilters('available')}
            name="Available"
            disabled={formik.dirty}
          />
        </UnsavedChangesTooltip>
        <UnsavedChangesTooltip disabled={!formik.dirty}>
          <NumberFilter
            activeFilter={dataTable.stock as NumberFilterValue}
            setFilter={filter => dataTable.setFilter('stock', filter)}
            clearFilter={() => dataTable.clearFilters('stock')}
            name="Total"
            customFilters={[{ id: 'na', name: 'is N/A', defaultValue: 'N/A' }]}
            disabled={formik.dirty}
          />
        </UnsavedChangesTooltip>
      </FilterList>
      <ClearFiltersButton disabled={hasNoAppliedFilters || formik.dirty} onClick={clearAllFilters} />
    </>
  )
}

export default Filters
