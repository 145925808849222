import { Button, IconButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { BulkOrderDesign, Design as DesignObjectType, DesignType } from '@packages/types'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import qs from 'qs'
import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import useProductService from 'common/products/hooks/useProductService'
import TenantContext from 'common/tenant/TenantContext'
import PrintTextIcon from 'icons/bold/01-Interface Essential/30-Print/print-picture.svg'
import LeftArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'
import RightArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'
import EyeIcon from 'icons/core-solid/interface-essential/interface-edit-view.svg'
import { formatFullDate } from 'utils/dateUtils'

import { defaultPersistenceState, getDesignPreviewUrl } from '../utils'
import useDesignService from './../hooks/useDesignService'
import DesignDetails from './DesignDetails'

type IndexedDesigns = {
  previous?: DesignObjectType
  next?: DesignObjectType
}

const Design = () => {
  const { id: designId, brandName } = useParams<{ id: string; brandName?: string }>()
  const location = useLocation<{ persistence?: typeof defaultPersistenceState }>()
  const history = useHistory()
  const designService = useDesignService()
  const productService = useProductService()
  const onlineStoreService = useOnlineStoreService()
  const baseUrl = brandName ? `/brands/${brandName}` : ''
  const persistence = location.state?.persistence
  const tenant = useContext(TenantContext)

  const {
    data,
    hasPreviousPage,
    hasNextPage,
    fetchPreviousPage,
    fetchNextPage,
    isLoading: isLoadingInifiniteDesigns,
    isFetching: isFetchingInifiniteDesigns,
  } = useInfiniteQuery(
    [...designService.fetchAll.queryKeys, persistence, 'infinite'],
    ({ pageParam = persistence?.lastIndex || 0 }) => designService.fetchAll({ ...persistence, lastIndex: pageParam }),
    {
      enabled: !!persistence,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getPreviousPageParam: currentPage => {
        const { lastIndex, resultSize } = currentPage.pagination
        const firstIndex = lastIndex - resultSize - persistence!.count
        return firstIndex >= 0 ? firstIndex : undefined
      },
      getNextPageParam: currentPage => {
        const { lastIndex, collectionSize } = currentPage.pagination
        return lastIndex < collectionSize ? lastIndex : undefined
      },
    }
  )

  const { data: directDesign, isLoading: isLoadingDirectDesign } = useQuery(
    [...designService.fetch.queryKeys, designId],
    () => designService.fetch(designId),
    { enabled: !persistence }
  )

  const { data: onlineStores = [], isLoading: isLoadingOnlineStores } = useQuery(
    onlineStoreService.fetchAll.queryKeys,
    () => onlineStoreService.fetchAll()
  )

  const handleGoBack = () => {
    if (persistence) {
      const currentPageIndex = (data?.pages || []).findIndex(page => page.results.find(({ id }) => id === designId))
      const lastIndex = data?.pageParams?.[currentPageIndex] || persistence.lastIndex
      const query = qs.stringify({ ...persistence, lastIndex }, { encode: false, skipNulls: true })
      history.push(`${baseUrl}/designs?${query}`)
    } else {
      history.push(`${baseUrl}/designs`)
    }
  }

  const isLoadingDesigns = persistence ? isLoadingInifiniteDesigns : isLoadingDirectDesign

  let designSiblings: IndexedDesigns = {}
  let design: DesignObjectType | undefined

  if (!!persistence) {
    const allDesigns = (data?.pages || []).reduce<DesignObjectType[]>(
      (result, page) => [...result, ...page.results],
      []
    )
    const currentPosition = allDesigns.findIndex(({ id }) => id === designId)

    designSiblings = {
      previous: allDesigns[currentPosition - 1],
      next: allDesigns[currentPosition + 1],
    }

    design = allDesigns[currentPosition]
  } else {
    design = directDesign
  }

  const { data: product } = useQuery(
    [...productService.fetch.queryKeys, design?.productId],
    () => productService.fetch(design!.productId),
    { enabled: !!design?.productId, retry: false }
  )

  const onlineStore = onlineStores?.find(({ id }) => id === design?.store)

  useEffect(() => {
    if (!designSiblings.previous && hasPreviousPage) fetchPreviousPage()
    if (!designSiblings.next && hasNextPage) fetchNextPage()
  })

  return (
    <main>
      <Header />
      <SideMenu />
      <Page>
        <Page.Header>
          <div className="flex">
            <IconButton Icon={LeftArrow} onClick={handleGoBack} className="print:hidden mr-2" aria-label="Go back" />
            <h1>
              <div className="flex-col">
                <div className="flex items-center mb-2 pl-2">
                  {!isLoadingDesigns && `Design #${design?.designId}`}
                  {isLoadingDesigns && <div className="animate-pulse h-8 bg-neutral-75 rounded max-w-xs w-64" />}
                </div>
                <div className="text-xs font-medium text-neutral-600 mx-2">
                  {design?.createdAt && formatFullDate(design.createdAt)}
                </div>
              </div>
            </h1>
          </div>
          <div className="flex">
            {product && (
              <Button
                icon={<EyeIcon className="w-4 h-4" />}
                variant="default"
                className="mx-1 print:hidden"
                onClick={() => {
                  window.open(getDesignPreviewUrl(tenant, design!.designId), '_blank')
                }}
                disabled={isLoadingDesigns}
                isLoading={isLoadingDesigns}
              >
                Preview
              </Button>
            )}
            <Button
              icon={<PrintTextIcon className="w-4 h-4" />}
              variant="default"
              className="mr-3 ml-1 print:hidden"
              onClick={window.print}
            >
              Print design
            </Button>
            {!!persistence && (
              <div className="print:hidden flex flex-[2] pl-2 border-l border-neutral-100">
                <IconButton
                  Icon={LeftArrow}
                  variant="default"
                  className="mx-1 print:hidden"
                  onClick={() =>
                    designSiblings.previous &&
                    history.replace(`${baseUrl}/designs/${designSiblings.previous.id}`, location.state)
                  }
                  disabled={isFetchingInifiniteDesigns || !designSiblings.previous}
                  isLoading={isFetchingInifiniteDesigns}
                  aria-label="previous"
                />
                <IconButton
                  Icon={RightArrow}
                  variant="default"
                  className="mx-1 print:hidden"
                  onClick={() =>
                    designSiblings.next &&
                    history.replace(`${baseUrl}/designs/${designSiblings.next.id}`, location.state)
                  }
                  disabled={isFetchingInifiniteDesigns || !designSiblings.next}
                  isLoading={isFetchingInifiniteDesigns}
                  aria-label="next"
                />
              </div>
            )}
          </div>
        </Page.Header>

        <Card className="w-full flex flex-col flex-1 print:shadow-[none]">
          {design?.type === DesignType.BulkOrderDesign ? (
            design.designs.map(({ design: childDesign }, index) => {
              const bulkDesign = design as BulkOrderDesign
              return (
                <div className={bulkDesign.designs.length - 1 != index ? 'print:break-after-page' : ''}>
                  <DesignDetails
                    designId={childDesign.id}
                    design={childDesign}
                    isLoadingDesigns={isLoadingDesigns}
                    isLoadingOnlineStores={isLoadingOnlineStores}
                    onlineStore={onlineStore}
                    isFromBulkOrder
                    index={index + 1}
                  />
                </div>
              )
            })
          ) : (
            <DesignDetails
              design={design}
              designId={designId}
              isLoadingDesigns={isLoadingDesigns}
              isLoadingOnlineStores={isLoadingOnlineStores}
              onlineStore={onlineStore}
            />
          )}
        </Card>
      </Page>
    </main>
  )
}

export default Design
