import { AnswerRef } from '@packages/types'
import { createSelector } from 'reselect'

import { bulkOrderRowCustomizationSelector, bulkOrderRowsSelector } from 'customizer/bulkOrder/selectors'
import type { CustomizationState } from 'customizer/customization/reducer/reducer'
import { customizationSelector } from 'customizer/customization/selectors'
import { RootState } from 'customizer/store'

import { StocksState } from './reducer'

export const stocksSelector = ({ stocks }: RootState) => stocks

export const selectedAnswerRefsSelector = (customization: CustomizationState) => {
  const selectedAnswers: AnswerRef[] = []

  if (customization.questions) {
    Object.values(customization.questions).forEach(question => {
      if (question.isMultiAnswer && question.selectedAnswers) {
        question.selectedAnswers.forEach(selectedAnswer => {
          selectedAnswers.push({ questionId: question.id, answerId: selectedAnswer })
        })
      } else if (question.selectedAnswer) {
        selectedAnswers.push({ questionId: question.id, answerId: question.selectedAnswer })
      }
    })
  }

  return selectedAnswers
}

export const questionStocksSelector = (stocks: StocksState, questionId: string) => {
  return Object.values(stocks).filter(stock => !!stock.answersRefs.find(ref => ref.questionId === questionId))
}

export const answersOutOfStockForQuestionSelector = createSelector(
  stocksSelector,
  customizationSelector,
  (_state: RootState, questionId: string) => questionId,
  (stocks: StocksState, customization: CustomizationState, questionId: string) => {
    const questionStocks = questionStocksSelector(stocks, questionId)
    if (!questionStocks.length) return []

    const selectedAnswersRefs = selectedAnswerRefsSelector(customization)
    const questionAnswerIds = customization.questions[questionId].answers || []

    const isQuestionLast = (answersRefs: AnswerRef[]) => {
      const lastQuestionId = answersRefs.reduce<string>((lastQuestionId, ref) => {
        if (!lastQuestionId) return ref.questionId

        return selectedAnswersRefs.findIndex(answerRef => answerRef.questionId === lastQuestionId) >
          selectedAnswersRefs.findIndex(answerRef => answerRef.questionId === ref.questionId)
          ? lastQuestionId
          : ref.questionId
      }, '')

      return lastQuestionId === questionId
    }

    return questionAnswerIds.reduce<string[]>((outOfStockAnswerIds, answerId) => {
      const questionStocksForAnswer = Object.values(questionStocks).filter(stock =>
        stock.answersRefs.every(answerRef =>
          questionId === answerRef.questionId
            ? answerRef.answerId === answerId
            : selectedAnswersRefs.find(
                selectedAnswerRef =>
                  selectedAnswerRef.answerId === answerRef.answerId &&
                  selectedAnswerRef.questionId === answerRef.questionId
              )
        )
      )

      if (questionStocksForAnswer.some(({ answersRefs, stock }) => stock === 0 && isQuestionLast(answersRefs))) {
        return [...outOfStockAnswerIds, answerId]
      }
      return outOfStockAnswerIds
    }, [])
  }
)

export const answersOutOfStockForQuestionsSelector = (state: RootState, questionIds: string[]) => {
  return questionIds.reduce((answersOutOfStock, id) => {
    return [...answersOutOfStock, ...answersOutOfStockForQuestionSelector(state, id)]
  }, [] as string[])
}

export const isQuestionOutOfStockSelector = (state: RootState, questionId: string) => {
  const question = state.customization.questions[questionId]

  if (!question) return false

  const outOfStockAnswers = answersOutOfStockForQuestionSelector(state, question.id)

  const selectedAnswerIds =
    question.isMultiAnswer && question.selectedAnswers
      ? question.selectedAnswers
      : question.selectedAnswer
        ? [question.selectedAnswer]
        : []

  return selectedAnswerIds.some(answerId => outOfStockAnswers.includes(answerId))
}

export const isQuestionOverAvailableStocksSelector = (state: RootState, questionId: string) => {
  const question = state.customization.questions[questionId]
  if (!question) return false

  const stocksWithAnswersOverAvailable = stocksWithAnswersOverAvailableSelector(state)

  return !!stocksWithAnswersOverAvailable.find(
    ({ answersRefs }) => !!answersRefs.find(answersRef => questionId === answersRef.questionId)
  )
}

export const isSelectionOutOfStockSelector = createSelector(
  customizationSelector,
  stocksSelector,
  (customization: CustomizationState, stocks: StocksState) => {
    const selectedAnswers = selectedAnswerRefsSelector(customization)

    const selectedAnswerStocks = Object.values(stocks).filter(stock =>
      stock.answersRefs.every(ref =>
        selectedAnswers.find(selRef => selRef.answerId === ref.answerId && selRef.questionId === ref.questionId)
      )
    )

    const isOutOfStock = selectedAnswerStocks.some(({ stock }) => stock === 0)
    const continueSelling = !selectedAnswerStocks.find(({ continueSelling }) => !continueSelling)

    return isOutOfStock && !continueSelling
  }
)

export const stocksWithAnswersOverAvailableSelector = createSelector(
  customizationSelector,
  stocksSelector,
  bulkOrderRowsSelector,
  (customization, stocks, bulkOrderRows) => {
    const quantityByVariantsId: Record<string, { quantity: number }> = {}

    bulkOrderRows.forEach(row => {
      const rowCustomization = bulkOrderRowCustomizationSelector(customization, row)

      const selectedAnswers = selectedAnswerRefsSelector(rowCustomization)
      const selectedAnswerStocks = Object.values(stocks).filter(stock =>
        stock.answersRefs.every(ref =>
          selectedAnswers.find(selRef => selRef.answerId === ref.answerId && selRef.questionId === ref.questionId)
        )
      )

      selectedAnswerStocks.forEach(selectedAnswerStock => {
        quantityByVariantsId[selectedAnswerStock.id] = {
          quantity: quantityByVariantsId[selectedAnswerStock.id]
            ? quantityByVariantsId[selectedAnswerStock.id].quantity + row.quantity
            : row.quantity,
        }
      })
    })

    return Object.keys(quantityByVariantsId)
      .filter(key => {
        return (
          quantityByVariantsId[key].quantity > stocks[key].stock &&
          !stocks[key].continueSelling &&
          stocks[key].stock > 0
        )
      })
      .map(key => stocks[key])
  }
)
