import { Alert } from '@packages/sk8/alert'
import { NormalizedCustomizerProduct } from '@packages/types'
import React from 'react'

import useTotalVariantsCount from '../modals/useTotalVariantsCount'

export interface TooManyVariantsAlertProps {
  variantsCombination: string[]
  customizerProduct: NormalizedCustomizerProduct
}

const TooManyVariantsAlert = ({ variantsCombination, customizerProduct }: TooManyVariantsAlertProps) => {
  const totalVariantCounts = useTotalVariantsCount(variantsCombination, customizerProduct)

  return totalVariantCounts.isOverMax ? (
    <Alert variant="error" className="mb-4">
      <Alert.Title>Too many variants</Alert.Title>
      <Alert.Details>
        This combination exceeds the maximum number of variants. Some variants were not generated. Please modify the
        combination or remove answers from the questions.
      </Alert.Details>
    </Alert>
  ) : null
}

export default TooManyVariantsAlert
