import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import React from 'react'

import UpdateCount from 'common/components/UpdateCount'
import { trpc } from 'common/hooks/trpc'

import { FormikVariants } from '../types'

interface StopSellingModalProps {
  ids: string[]
  handleClose: () => void
  modalProps: Omit<React.ComponentProps<typeof Modal>, 'children'>
  formik: FormikVariants
  onSuccess: () => void
}

const StopSellingModal = ({ ids, onSuccess, handleClose, modalProps }: StopSellingModalProps) => {
  const { openToast } = useToast()

  const { mutate: bulkUpdate, isLoading: isSaving } = trpc.variant.bulkUpdateContinueSelling.useMutation({
    onSuccess: () => {
      onSuccess()
      openToast('Variants was successfully saved.', ToastType.success)
    },
    onError: () => {
      openToast('Could not save variants please try again.', ToastType.warning)
    },
  })

  const handleSave = () => {
    return bulkUpdate({ ids, continueSelling: false }, { onSettled: handleClose })
  }

  return (
    <Modal onBackdropClick={handleClose} {...modalProps}>
      <Modal.CloseButton onClick={handleClose} />
      <Modal.Title>Stop selling when out of stock</Modal.Title>
      <Modal.Details>Variants will not be available for sale when their quantities reaches 0.</Modal.Details>
      <Modal.Actions className="justify-between items-center">
        <UpdateCount text="variant" count={ids.length} />
        <div className="flex space-x-3">
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSave} disabled={isSaving} isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default StopSellingModal
