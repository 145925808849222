import { Placement } from '@floating-ui/react-dom'
import { BetaTag } from '@packages/sk8/tag'
import { Tooltip } from '@packages/sk8/tooltip'
import { EntityType, GroupType, QuestionInputType } from '@packages/types'
import classNames from 'classnames'
import { useFlags } from 'flagsmith/react'
import React, { useState } from 'react'

import TypeIcon from 'builder/build/common/components/TypeIcon'
import dropdown from 'videos/dropdown.webm'
import label from 'videos/label-button.webm'
import radio from 'videos/radio-button.webm'
import text from 'videos/text-input.webm'
import thumbnailsImage from 'videos/thumbnail.webm'
import fileUpload from 'videos/upload-file.webm'

import QuestionMenuCard from './QuestionMenuCard'
import { bulkOrderInsideGroupsWarning, onlyOneBulkOrderWarning } from './ToastTexts'
import VideoPreviewModal from './VideoPreviewModal'

const noneQuestionType = { type: QuestionInputType.None, label: 'None', preview: null }

const creatableQuestionTypes = [
  { type: QuestionInputType.Thumbnail, label: 'Thumbnail', preview: thumbnailsImage },
  { type: QuestionInputType.Dropdown, label: 'Dropdown', preview: dropdown },
  { type: QuestionInputType.SmallRadio, label: 'Radio button', preview: radio },
  { type: QuestionInputType.Label, label: 'Label', preview: label },
  { type: QuestionInputType.File, label: 'File upload', preview: fileUpload },
  { type: QuestionInputType.Text, label: 'Text input', preview: text },
  { type: QuestionInputType.Checkbox, label: 'Checkbox', preview: null },
  { type: QuestionInputType.ColorPicker, label: 'Color picker', preview: null },
]

interface QuestionTypesStepProps {
  selectedQuestionType: string | null
  title: string
  onSelect: (type: QuestionInputType | GroupType) => void
  isInGroup: boolean
  isInBulkOrderGroup: boolean
  gifPlacement: Placement
  hasBulkOrderGroup: boolean
}

const QuestionTypesStep = ({
  selectedQuestionType,
  title,
  onSelect,
  isInGroup = true,
  isInBulkOrderGroup = false,
  hasBulkOrderGroup,
  gifPlacement,
}: QuestionTypesStepProps) => {
  const questionTypes = !isInGroup ? [...creatableQuestionTypes, noneQuestionType] : creatableQuestionTypes
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
  const [hoveredItemPreview, setHoveredItemPreview] = useState<string | null>(null)

  const flags = useFlags(['bulk_order'])

  return (
    <div className="new-question-menu__step">
      <div className="new-question-menu__step-container">
        <div className="new-question-menu__title">
          <span>{title}</span>
        </div>
        <div className="new-question-menu__grid" ref={setContainerRef}>
          <VideoPreviewModal
            key={hoveredItemPreview}
            src={hoveredItemPreview}
            containerRef={containerRef}
            placement={gifPlacement}
          />
          {questionTypes.map(questionType => (
            <QuestionMenuCard
              entityType={EntityType.Question}
              item={questionType}
              isSelected={questionType.type === selectedQuestionType}
              onSelect={onSelect}
              setHoveredItemPreview={setHoveredItemPreview}
            />
          ))}

          <>
            <div
              className={classNames('new-question-menu__card', {
                'new-question-menu__card--selected': GroupType.Folder === selectedQuestionType,
                'new-question-menu__card--disabled': isInBulkOrderGroup,
              })}
              onClick={isInBulkOrderGroup ? undefined : () => onSelect(GroupType.Folder)}
            >
              <TypeIcon
                className="w-[26px] h-[26px] mr-2 p-1.5 rounded"
                entityType={EntityType.Group}
                specificType={GroupType.Folder}
              />
              <span className="new-question-menu__label">Group</span>
            </div>

            {flags.bulk_order.enabled && (
              <Tooltip content={isInGroup ? bulkOrderInsideGroupsWarning : onlyOneBulkOrderWarning}>
                <div
                  className={classNames('new-question-menu__card', {
                    'new-question-menu__card--selected': GroupType.BulkOrder === selectedQuestionType,
                    'new-question-menu__card--disabled': hasBulkOrderGroup || isInGroup,
                  })}
                  onClick={hasBulkOrderGroup || isInGroup ? undefined : () => onSelect(GroupType.BulkOrder)}
                >
                  <TypeIcon
                    className="w-[26px] h-[26px] mr-2 p-1.5 rounded"
                    entityType={EntityType.Group}
                    specificType={GroupType.BulkOrder}
                  />
                  <span className="new-question-menu__label">Bulk order</span>
                  <BetaTag className="scale-75 absolute top-1 right-0" disableTooltip />
                </div>
              </Tooltip>
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export default QuestionTypesStep
