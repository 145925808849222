import { useEffect, useState } from 'react'
import { useProductFruitsApi } from 'react-product-fruits'
import { useHistory, useParams } from 'react-router'

import { trpc } from 'common/hooks/trpc'

const DEMO = 'Headphone'

const getTour = (toursApi: any) => {
  return toursApi?.getTours?.()?.find((tour: { id: number }) => String(tour?.id) === String(ONBOARDING_PRODUCT_TOUR_ID))
}

const useProductTour = () => {
  const params = useParams<{ brandName: string; productId: string }>()
  const history = useHistory()
  const baseUrl = params?.brandName ? `/brands/${params.brandName}` : ''

  const [isCompleted, setIsCompleted] = useState(false)
  const [productId, setProductId] = useState(localStorage.getItem('productTourReturnId') || '')
  const [toursApi, setToursApi] = useState<any>({
    markAs: () => {},
    getTours: () => [],
    tryStartTour: () => {},
  })

  useProductFruitsApi(api => {
    setToursApi(api.tours)

    const tour = getTour(api.tours)
    if (!tour?.isRunning && tour?.userState === 'not_finished' && !isCompleted) {
      api.tours?.tryStartTour?.(ONBOARDING_PRODUCT_TOUR_ID)
    }
  }, [])

  const tour = getTour(toursApi)
  const isActive = tour?.isRunning && tour?.userState === 'not_finished' && !isCompleted

  const { data: demo, isLoading: isLoadingDemo } = trpc.demoProduct.findByName.useQuery(DEMO, { enabled: !!isActive })

  const handleCustomEvents = (e: Event & { detail: { id: string } }) => {
    try {
      if (e.detail.id == 'go-to-demo') {
        if (!demo || isLoadingDemo) return
        setProductId(params.productId)
        localStorage.setItem('productTourReturnId', params.productId)
        toursApi.advanceToNextStep(ONBOARDING_PRODUCT_TOUR_ID)
        history.push(`${baseUrl}/demos/${demo?.id}/builder`)
      }

      if (e.detail.id == 'return-to-product') {
        toursApi.markAs(ONBOARDING_PRODUCT_TOUR_ID, 'finished')
        toursApi.advanceToNextStep(ONBOARDING_PRODUCT_TOUR_ID)
        history.push(`${baseUrl}/products/${productId}/builder`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!toursApi || !demo || !isActive) return

    window.addEventListener('productfruits_card_custom_btn_click', handleCustomEvents as EventListener)
    toursApi?.listen?.('tour-skipped', (id: number) => {
      if (id === ONBOARDING_PRODUCT_TOUR_ID) {
        toursApi.markAs(ONBOARDING_PRODUCT_HINT_ID, 'skipped')
        setIsCompleted(true)
      }
    })

    toursApi?.listen?.('tour-finished', (id: number) => {
      if (id === ONBOARDING_PRODUCT_TOUR_ID) {
        setIsCompleted(true)
      }
    })

    return () => {
      window.removeEventListener('productfruits_card_custom_btn_click', handleCustomEvents as EventListener)
    }
  }, [toursApi, demo, productId])

  return { isActive }
}

export default useProductTour
