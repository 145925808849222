import {
  Addon,
  CustomPlan,
  OnlineStore,
  PaymentStrategy,
  Plan,
  ShopifyOnlineStore,
  Subscription,
  SubscriptionStatus,
} from '@packages/types'
import React from 'react'

import ShopifyActivationCard from './ShopifyActivationCard'
import StripeActivationCard from './StripeActivationCard'
import StripeChangePlanCard from './StripeChangePlanCard'
import WixActivationCard from './WixActivationCard'

export type ActivateOrChangePlanCardProps = {
  onlineStores: OnlineStore[]
  plan: Plan | CustomPlan
  subscription: Subscription
  addons: Addon[]
  onWixSuccess: () => void
  onStripeActivateSuccess: () => void
  onStripeChangePlanSuccess: () => void
}

const ActivateOrChangePlanCard = ({
  onlineStores,
  plan,
  subscription,
  addons,
  onWixSuccess,
  onStripeActivateSuccess,
  onStripeChangePlanSuccess,
}: ActivateOrChangePlanCardProps) => {
  if (subscription.paymentStrategy === PaymentStrategy.Shopify) {
    return (
      <ShopifyActivationCard
        onlineStores={onlineStores as ShopifyOnlineStore[]}
        plan={plan}
        subscription={subscription}
        addons={addons}
      />
    )
  }

  if (subscription.paymentStrategy === PaymentStrategy.Wix) {
    return (
      <WixActivationCard
        onlineStores={onlineStores}
        plan={plan}
        subscription={subscription}
        addons={addons}
        onSuccess={onWixSuccess}
      />
    )
  }

  if (subscription.paymentStrategy === PaymentStrategy.Stripe) {
    const stripeFreeTrialActivated =
      subscription.paymentStrategy === PaymentStrategy.Stripe &&
      subscription.status === SubscriptionStatus.FreeTrial &&
      !!subscription.stripePaymentMethodId

    return subscription.status === SubscriptionStatus.Active || stripeFreeTrialActivated ? (
      <StripeChangePlanCard
        subscription={subscription}
        plan={plan}
        addons={addons}
        onSuccess={onStripeChangePlanSuccess}
      />
    ) : (
      <StripeActivationCard plan={plan} addons={addons} onSuccess={onStripeActivateSuccess} />
    )
  }

  return null
}

export default ActivateOrChangePlanCard
