import { Button, ScrollToTopButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import {
  CheckboxFilter,
  ClearFiltersButton,
  FilterContainer,
  FilterList,
  NumberFilter,
  SearchFilter,
  TagsFilter,
} from '@packages/sk8/filter'
import { Tabs } from '@packages/sk8/tab'
import { Table, useInfiniteTableAddons, useStickyHeader } from '@packages/sk8/table'
import { NormalizedCustomizerProduct } from '@packages/types'
import classNames from 'classnames'
import React, { MutableRefObject, useCallback, useRef, useState } from 'react'
import { TableVirtuoso } from 'react-virtuoso'

import { getCombinationName } from 'builder/variants/utils'
import UnsavedChangesTooltip from 'common/components/filters/UnsavedChangesTooltip'
import UpdateCount from 'common/components/UpdateCount'
import useBulkSelection from 'common/hooks/useBulkSelection'
import useDoubleScrollbar from 'common/hooks/useDoubleScrollbar'
import useScrollRight from 'common/hooks/useScrollRight'

import { InventoryDataTable } from '../../types/dataTable'
import { FormikVariants } from '../types'
import { getDirtyVariantIds, getInventoryItem } from '../utils'
import BlankState from './../blankStates/BlankState'
import useVariantsColumns from './useVariantsColumns'
import useVariantsFilter from './useVariantsFilters'

export interface VariantsTableProps {
  formik: FormikVariants
  locationId: string
  variantsCombination: string[]
  customizerProduct: NormalizedCustomizerProduct
  onChangeCombinationClick: (e: React.MouseEvent) => void
  scrollParentRef: MutableRefObject<null | HTMLDivElement>
  fetchMore: () => void
  dataTable: InventoryDataTable
  variantIds: string[]
  isFetching: boolean
}

const VariantsTable = ({
  formik,
  locationId,
  variantsCombination = [],
  customizerProduct,
  onChangeCombinationClick,
  scrollParentRef,
  fetchMore,
  dataTable,
  variantIds,
  isFetching,
}: VariantsTableProps) => {
  const variants = Object.values(formik.values.variants).filter(variant => variantIds.includes(variant.id))
  const [focusedSku, setFocusedSku] = useState<string | undefined>()

  const cardRef: MutableRefObject<HTMLElement | null> = useRef(null)
  const { StickyHeader, StickyHeaderContainer, setStickyScrollerRef } = useStickyHeader(101)

  const { tableProps, stickyFilterContainerProps, scrollToTopButtonProps } = useInfiniteTableAddons({ scrollParentRef })

  const [notScrolled, fullyScrolled, { ref: setScrollRightRef, ...scrollProps }] = useScrollRight()

  const {
    componentProps: { ref: setComponentRef },
    outerComponentProps,
    innerComponentProps,
  } = useDoubleScrollbar()

  const setHorizontalScrollRef = (el: HTMLDivElement) => {
    setScrollRightRef(el)
    setComponentRef(el)
    setStickyScrollerRef(el)
    cardRef.current = el
  }

  const filters = useVariantsFilter(dataTable)

  const bulkSelection = useBulkSelection(variantIds)

  const columns = useVariantsColumns(locationId, variantsCombination, notScrolled, fullyScrolled)

  const emptyPlaceholderRender = useCallback(
    () => (
      <div className="flex sticky left-1/2 w-80 -translate-x-1/2 justify-center pt-12">
        <div className="max-w-sm">
          <BlankState
            areFiltersEmpty={filters.areFiltersEmpty}
            isNewTabSelected={dataTable.isNewFromPublish === true}
            handleChangeQuestionsClick={onChangeCombinationClick}
            handleViewAllVariantsClick={() => dataTable.setFilter('isNewFromPublish', undefined)}
            handleClearFiltersClick={filters.clearAllFilters}
          />
        </div>
      </div>
    ),
    [filters.areFiltersEmpty, dataTable.isNewFromPublish]
  )

  const hasNewVariant = !!variants.find(variant => variant.isNewFromPublish)
  const combinationName = getCombinationName(variantsCombination, customizerProduct)

  const Scroller = useCallback(
    (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
      <div className="overflow-x-auto w-full">
        <div
          className="overflow-y-hidden"
          {...props}
          {...scrollProps}
          ref={setHorizontalScrollRef}
          style={{ ...props.style, height: variants.length === 0 ? '300px' : props.style?.height }}
        />
      </div>
    ),
    [variants.length === 0]
  )

  const dirtyVariantIds = getDirtyVariantIds(formik)

  return (
    <>
      <h2 className="mb-4 h-8 flex items-center">{combinationName}</h2>
      <Card className="w-full overflow-visible bg-white mb-8">
        <div className="flex flex-col grow relative w-[800px]">
          <div className="flex items-center px-6 pt-2 border-b border-neutral-100">
            <Tabs className="mr-6 border-b-0 !h-8">
              <UnsavedChangesTooltip disabled={!formik.dirty}>
                <Tabs.Tab
                  isSelected={dataTable.isNewFromPublish != true}
                  onClick={() => dataTable.setFilter('isNewFromPublish', undefined)}
                  disabled={formik.dirty}
                >
                  All
                </Tabs.Tab>
              </UnsavedChangesTooltip>
              <UnsavedChangesTooltip disabled={!formik.dirty}>
                <Tabs.Tab
                  isSelected={dataTable.isNewFromPublish === true}
                  onClick={() => dataTable.setFilter('isNewFromPublish', true)}
                  disabled={formik.dirty}
                >
                  <div className="flex space-x-1 items-center">
                    <span>New</span>
                    {dataTable.isNewFromPublish !== true && hasNewVariant && (
                      <div className="bg-tertiary-green-200 h-1.5 w-1.5 rounded-full animate-pulse absolute -right-2 top-1.5" />
                    )}
                  </div>
                </Tabs.Tab>
              </UnsavedChangesTooltip>
            </Tabs>
          </div>
          <FilterContainer
            variant="card"
            className={classNames('sticky top-[52px] z-[2] bg-white', {
              'rounded-t-xl': stickyFilterContainerProps.isScrollAtTop,
            })}
          >
            <FilterList>
              <UnsavedChangesTooltip disabled={!formik.dirty}>
                <SearchFilter
                  name="Search"
                  setSearch={filters.setSearchFilter}
                  search={filters.searchFilter}
                  disabled={formik.dirty}
                />
              </UnsavedChangesTooltip>
              {variantsCombination.map(questionId => (
                <UnsavedChangesTooltip key={`filter-${questionId}-tooltip`} disabled={!formik.dirty}>
                  <TagsFilter
                    name={customizerProduct.questions[questionId].name}
                    toggleFilter={answerId => filters.toggleAnswersFilters(questionId, answerId)}
                    activeFilters={filters.answersFilters?.[questionId] ?? []}
                    clearFilter={() => filters.clearAnswersFilters(questionId)}
                    filters={customizerProduct.questions[questionId].answers.map(answerId => ({
                      name: answerId,
                      text: customizerProduct.answers[answerId]?.name,
                      className: 'bg-neutral-100',
                    }))}
                    disabled={formik.dirty}
                  />
                </UnsavedChangesTooltip>
              ))}
              <UnsavedChangesTooltip disabled={!formik.dirty}>
                <CheckboxFilter
                  name="Continue selling"
                  toggleFilter={filters.toggleStatusFilters}
                  activeFilters={filters.statusFilters}
                  clearFilter={filters.clearStatusFilters}
                  filters={[
                    {
                      name: 'continue-selling',
                      text: 'Continue selling',
                      className: 'text-primary-900',
                    },
                    {
                      name: 'stop-selling',
                      text: 'Stop selling',
                    },
                  ]}
                  disabled={formik.dirty}
                />
              </UnsavedChangesTooltip>
              <UnsavedChangesTooltip disabled={!formik.dirty}>
                <NumberFilter
                  activeFilter={filters.stockFilter}
                  setFilter={filters.setStockFilter}
                  clearFilter={filters.clearStockFilter}
                  name="Total"
                  customFilters={[{ id: 'na', name: 'is N/A', defaultValue: 'N/A' }]}
                  disabled={formik.dirty}
                />
              </UnsavedChangesTooltip>
            </FilterList>
            <ClearFiltersButton onClick={filters.clearAllFilters} disabled={filters.areFiltersEmpty || formik.dirty} />
          </FilterContainer>
          <StickyHeaderContainer />

          <TableVirtuoso
            {...tableProps}
            useWindowScroll
            customScrollParent={scrollParentRef.current || undefined}
            totalCount={variants.length}
            defaultItemHeight={41}
            overscan={200}
            atBottomThreshold={20}
            endReached={() => {
              fetchMore()
            }}
            components={{
              EmptyPlaceholder: emptyPlaceholderRender,
              Table: Table,
              TableBody: Table.Body,
              TableRow: Table.Row,
              TableHead: StickyHeader,
              Scroller: Scroller,
            }}
            fixedHeaderContent={() => {
              return (
                <>
                  <Table.HeaderRow className="bg-white">
                    {columns.map(column => {
                      return (
                        <Table.HeaderCell key={column.key} className={column.className}>
                          <span>{column.title(formik, bulkSelection, customizerProduct, variants)}</span>
                        </Table.HeaderCell>
                      )
                    })}
                  </Table.HeaderRow>
                  {isFetching && <Table.Loader colSpan={6} className="absolute z-[2] top-[36px] w-full" />}
                </>
              )
            }}
            itemContent={index => {
              const variant = variants[index]

              const inventoryItem = getInventoryItem(formik, variant)

              return (
                <>
                  {columns.map(column => {
                    return (
                      <Table.Cell
                        key={column.key}
                        className={classNames(column.cellClassName, 'transition-colors', {
                          'bg-tertiary-green-50': variant.isNewFromPublish,
                        })}
                        onFocus={() => setFocusedSku(inventoryItem?.sku === '' ? undefined : inventoryItem?.sku)}
                        onBlur={() => setFocusedSku(sku => (sku === inventoryItem?.sku ? undefined : sku))}
                      >
                        {column.render(variant, formik, bulkSelection, customizerProduct, focusedSku)}
                      </Table.Cell>
                    )
                  })}
                </>
              )
            }}
          />
          <div {...outerComponentProps} style={{ ...outerComponentProps.style, position: 'sticky', bottom: '81px' }}>
            <div {...innerComponentProps} />
          </div>
          <Card.StickyFooter>
            <ScrollToTopButton {...scrollToTopButtonProps} />
            <UpdateCount text="variant" count={dirtyVariantIds.length} />
            <div className="flex justify-end px-6 py-3">
              <Button
                type="reset"
                className="mr-2"
                disabled={!formik.dirty || formik.isSubmitting}
                onClick={() => formik.resetForm()}
              >
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => formik.submitForm()}
                disabled={!formik.dirty || formik.isSubmitting}
                isLoading={formik.isSubmitting}
              >
                Save
              </Button>
            </div>
          </Card.StickyFooter>
        </div>
      </Card>
    </>
  )
}

export default VariantsTable
