import {
  ClearFiltersButton,
  DatesFilter,
  FilterContainer,
  FilterList,
  SearchFilter,
  TagsFilter,
} from '@packages/sk8/filter'
import { QuoteStatus } from '@packages/types'
import React from 'react'

import type { QuotesFilters } from 'cms/quotes/types/datatable'
import withFlag from 'common/users/components/withFlag'

import OnlineStoresFilter from './OnlineStoresFilter'

export const STATUS_FILTERS = [
  {
    name: QuoteStatus.Draft,
    text: 'Draft',
    className: 'bg-neutral-75',
  },
  {
    name: QuoteStatus.Canceled,
    text: 'Canceled',
    className: 'bg-tertiary-red-75',
  },
  {
    name: QuoteStatus.Open,
    text: 'Open',
    className: 'bg-primary-75',
  },
  {
    name: QuoteStatus.Accepted,
    text: 'Accepted',
    className: 'bg-tertiary-green-75',
  },
]

export interface QuotesFilterListProps {
  setSearch: (value: string) => void
  search?: string
  startDate?: Date | null
  setStartDate: (value?: Date | null) => void
  endDate?: Date | null
  setEndDate: (value?: Date | null) => void
  expirationStartDate?: string | null
  expirationEndDate?: string | null
  clearAll: () => void
  hasAppliedFilters?: boolean
  stores: string[]
  status: string[]
  toggleFilter: (filter: keyof QuotesFilters, name: string) => void
  clearFilters: (filter: 'stores' | 'status') => void
}

const QuotesFilterList = ({
  setSearch,
  search,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  expirationStartDate,
  expirationEndDate,
  clearAll,
  hasAppliedFilters,
  stores,
  status,
  toggleFilter,
  clearFilters,
}: QuotesFilterListProps) => {
  return (
    <FilterContainer>
      <FilterList>
        <SearchFilter name="Quote" setSearch={setSearch} search={search} />
        <DatesFilter
          name="Created"
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <DatesFilter
          name="Expiration"
          startDate={expirationStartDate ? new Date(expirationStartDate) : null}
          setStartDate={value => value && toggleFilter('expirationStartDate', value.toISOString())}
          endDate={expirationEndDate ? new Date(expirationEndDate) : null}
          setEndDate={value => value && toggleFilter('expirationEndDate', value.toISOString())}
        />
        <TagsFilter
          name="Status"
          filters={STATUS_FILTERS}
          activeFilters={status}
          toggleFilter={name => toggleFilter('status', name)}
          clearFilter={() => clearFilters('status')}
        />
        <OnlineStoresFilter
          clearFilters={() => clearFilters('stores')}
          toggleOnlineStore={(name: string) => toggleFilter('stores', name)}
          selectedOnlineStores={stores}
        />
      </FilterList>
      <ClearFiltersButton onClick={clearAll} disabled={!hasAppliedFilters} />
    </FilterContainer>
  )
}

export default withFlag({
  Component: QuotesFilterList,
  feature: 'quote_phase_1',
})
