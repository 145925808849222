import { Variant } from '@packages/types'
import { useEffect } from 'react'

import { FormikVariants } from '../types'
import { getFormValues } from '../utils'

const useInfiniteFormReset = (formik: FormikVariants, locationId: string, variantsData: Variant[]) => {
  useEffect(() => {
    const isHardReset = formik.status === 'submitted'

    const newValues = getFormValues(locationId, variantsData)

    const newInitialValues = isHardReset
      ? newValues
      : {
          variants: { ...newValues.variants, ...formik.initialValues.variants },
          inventoryItems: { ...newValues.inventoryItems, ...formik.initialValues.inventoryItems },
        }

    const values = isHardReset
      ? newValues
      : {
          variants: { ...newValues.variants, ...formik.values.variants },
          inventoryItems: { ...newValues.inventoryItems, ...formik.values.inventoryItems },
        }

    formik.resetForm({ values: newInitialValues, ...(isHardReset ? {} : { touched: formik.touched }) })
    formik.setValues(values)
  }, [variantsData])
}

export default useInfiniteFormReset
