import { Button } from '@packages/sk8/button'
import { Label, Select } from '@packages/sk8/input'
import { Modal, useModal } from '@packages/sk8/modal'
import { Tag } from '@packages/sk8/tag'
import { ToastType, useToast } from '@packages/sk8/toast'
import { TopBar } from '@packages/sk8/top-bar'
import {
  ActivityLogType,
  DemoProduct,
  DenormalizedTheme,
  OnlineStoreStatus,
  Product,
  ThemeBuilderConfig,
  ThemeSettings,
} from '@packages/types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FormikProvider, useFormik } from 'formik'
import { capitalize, isEmpty, orderBy } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import useBrandService from 'cms/brands/hooks/useBrandService'
import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import useAutoSave from 'cms/theme/hooks/useAutoSave'
import useTranslationService from 'cms/translations/hooks/useTranslationService'
import TopBarEnvTag from 'common/components/topBar/TopBarEnvTag'
import { trpc } from 'common/hooks/trpc'
import CustomizerOptionsControl from 'common/preview/components/CustomizerOptionsControl'
import PreviewIframe from 'common/preview/components/PreviewIframe'
import PreviewModeControl from 'common/preview/components/PreviewModeControl'
import ProductSelectControl from 'common/preview/components/ProductSelectControl'
import { PreviewMode } from 'common/preview/types/previewMode'
import useProductService from 'common/products/hooks/useProductService'
import * as customizerPricingActions from 'customizer/pricing/actions'
import * as customizerStocksActions from 'customizer/stocks/actions'
import * as customizerThemeActions from 'customizer/theme/actions'
import * as themesUtils from 'customizer/theme/utils'
import Skater from 'icons/custom/skater-rotate.svg'

import { getThemeConfig, mergeDefaultConfigWithTheme } from '../utils'
import PreviewOutOfStockSetting from './PreviewOutOfStockSetting'
import PublishDiscard from './PublishDiscard'
import SelectThemeModal from './SelectThemeModal'
import ThemeSettingsPanel from './ThemeSettingsPanel'
import ThemeSettingsSectionsPanel from './ThemeSettingsSectionsPanel'
import TopBarThemeActions from './TopBarThemeActions'

const ThemeBuilder = () => {
  const history = useHistory()
  const { openGenericErrorToast, openToast } = useToast()
  const resetThemeModal = useModal()
  const selectThemeModal = useModal()
  const brandService = useBrandService()
  const productService = useProductService()
  const translationService = useTranslationService()
  const onlineStoreService = useOnlineStoreService()
  const queryClient = useQueryClient()
  const params = useParams<{ brandName?: string; themeId: string }>()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [isReady, setIsReady] = useState(false)
  const [previewMode, setPreviewMode] = useState(PreviewMode.DESKTOP)
  const [productId, setProductId] = useState<string | undefined>(undefined)
  const [version, setVersion] = useState<'live' | 'draft'>('live')
  const [language, setLanguage] = useState<string | undefined>(undefined)
  const [pricing, setPricing] = useState<string | undefined>(undefined)
  const [outOfStockState, setOutOfStockState] = useState(false)
  const [settingSection, setSettingSection] = useState<string | undefined>()
  const [themeSettings, setThemeSettings] = useState<ThemeBuilderConfig['settings'] | undefined>()
  const [draftCreated, setDraftCreated] = useState(false)
  const trpcUtils = trpc.useContext()

  const handleBackClick = () => {
    const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''
    history.push(`${baseUrl}/products`)
  }

  const invalidateQueries = () => {
    if (params.themeId) trpcUtils.theme.get.invalidate({ id: params.themeId })
    else trpcUtils.theme.getCurrent.invalidate()
  }

  const { data: brand, isLoading: isLoadingBrand } = useQuery(
    brandService.fetchAccount.queryKeys,
    brandService.fetchAccount,
    {
      refetchOnWindowFocus: false,
      onSuccess: brand => {
        if (isEmpty(brand.customizerSettings?.theme)) selectThemeModal.open()
      },
    }
  )

  const themeQueryOptions = {
    select: (theme: DenormalizedTheme | null) => mergeDefaultConfigWithTheme(theme || undefined),
    onSuccess: (activeTheme: DenormalizedTheme) => {
      if (params.themeId && activeTheme.id !== params.themeId) return

      const themeSettings = getThemeConfig(activeTheme.name).settings
      setThemeSettings(themeSettings)
      setDraftCreated(false)
      if (!settingSection) setSettingSection(Object.keys(themeSettings)[0])
    },
    onError: () => {
      if (brand && isEmpty(brand.customizerSettings?.theme)) return

      openGenericErrorToast('The theme could not be found.')
      if (params.themeId) handleBackClick()
    },
  }

  const { data: currentTheme, isLoading: isLoadingCurrentTheme } = trpc.theme.getCurrent.useQuery(undefined, {
    ...themeQueryOptions,
    enabled: !params.themeId,
  })

  const { data: specificTheme, isLoading: isLoadingSpecificTheme } = trpc.theme.get.useQuery(
    { id: params.themeId },
    {
      ...themeQueryOptions,
      enabled: !!params.themeId,
    }
  )

  const theme = params.themeId ? specificTheme : currentTheme
  const isLoadingTheme = params.themeId ? isLoadingSpecificTheme : isLoadingCurrentTheme
  const themeId = theme?.id
  const isCurrentThemeForBrand = themeId === brand?.customizerSettings?.theme?.id

  const { data: products, isLoading: isLoadingProducts } = useQuery(
    [...productService.fetchAll.queryKeys, 'active'],
    () => productService.fetchAll({ params: { archived: false } }),
    {
      select: data => (data?.results || []) as Product[],
      onSuccess: data => {
        if (data.length > 0 && !productId) {
          const product = orderBy(data, 'updatedAt', 'desc')[0]
          setProductId(product.id)
          setVersion(product.draft ? 'draft' : 'live')
        }
      },
    }
  )

  const { data: demos, isLoading: isLoadingDemos } = trpc.demoProduct.list.useQuery(
    {},
    {
      select: data => data.results,
      onSuccess: data => {
        if (data.length > 0 && !productId) {
          const demo = orderBy(data, 'updatedAt', 'desc')[0]
          setProductId(demo.id)
          setVersion('live')
        }
      },
    }
  )

  const productsList = useMemo(() => [...(products || []), ...(demos || [])], [products, demos])

  const selectedProduct = productsList?.find(({ id }) => id === productId)

  const { data: translations, isLoading: isLoadingTranslations } = useQuery(
    translationService.fetchAll.queryKeys,
    translationService.fetchAll,
    { initialData: [] }
  )

  const { data: onlineStores = [], isLoading: isLoadingOnlineStores } = useQuery(
    onlineStoreService.fetchAll.queryKeys,
    onlineStoreService.fetchAll,
    {
      enabled: !!selectedProduct,
      refetchOnWindowFocus: false,
      select: data => data.filter(({ status }) => status === OnlineStoreStatus.Installed),
      onSuccess: stores =>
        setPricing(!(selectedProduct as DemoProduct).demoAttributes && stores[0]?.id ? stores[0].id : 'FAKE_PRICING'),
    }
  )

  const { mutate: selectTheme } = trpc.theme.select.useMutation({
    onSuccess: theme => {
      queryClient.invalidateQueries(brandService.fetchAccount.queryKeys)
      openToast(
        'The theme has been set to ' + capitalize(theme.name) + ' and was published successfully',
        ToastType.success
      )
    },
    onError: () => openGenericErrorToast('The theme was not published.'),
  })

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation({
    onSuccess: () => trpcUtils.activityLog.listLatest.invalidate(),
  })

  const { mutate: discardTheme, isLoading: isDiscarding } = trpc.theme.discardDraftById.useMutation({
    onSuccess: () => {
      openToast('The draft was successfully discarded', ToastType.success)
      invalidateQueries()
      formik.resetForm()
    },
    onError: () => openGenericErrorToast('The draft was not discarded.'),
  })

  const { mutateAsync: publishTheme } = trpc.theme.publishById.useMutation({
    onSuccess: async publishedTheme => {
      if (!publishedTheme) throw new Error('Theme was not published')
      invalidateQueries()

      if (!isCurrentThemeForBrand) {
        selectTheme({ name: publishedTheme.name, shouldUpdateBrand: true })
      } else {
        openToast('The draft was successfully published', ToastType.success)
      }
    },
    onError: () => {
      openGenericErrorToast('The theme was not published.')
    },
  })

  const initialValues = theme?.draft && !isEmpty(theme.draft.settings) ? theme.draft.settings : theme?.settings || {}
  const formik = useFormik<ThemeSettings>({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (_, { setSubmitting }) => {
      publishTheme(themeId!).then(() => setSubmitting(false))
      createActivityLog({ type: ActivityLogType.PublishTheme, details: { theme: theme!.name } })
    },
  })

  useAutoSave({
    themeId: themeId!,
    baseTheme: (theme?.draft || theme || {}) as DenormalizedTheme,
    formik: formik,
    isDraft: draftCreated || !!theme?.draft,
    onCreateDraft: () => {
      if (theme?.draft) theme.draft.isDraft = true
      setDraftCreated(true)
    },
  })

  const getIFrameSrc = () => {
    const searchParams = new URLSearchParams()

    if (language) searchParams.append('lang', language)
    if (pricing && pricing !== 'FAKE_PRICING') searchParams.append('shopid', pricing)
    if (params.brandName) searchParams.append('tenant', params.brandName)
    if (theme?.id) searchParams.append('themeId', theme.id)

    if ((selectedProduct as DemoProduct | undefined)?.demoAttributes) {
      return `${window.location.origin}/customize/demos/${productId}?${searchParams.toString()}`
    }
    if (selectedProduct) searchParams.append('version', (selectedProduct as Product)[version]!)

    return `${window.location.origin}/customize/${productId}?${searchParams.toString()}`
  }

  const getPricingOption = () => {
    const fakePricingOption = { label: 'Fake pricing (USD)', value: 'FAKE_PRICING' }

    if ((selectedProduct as DemoProduct | undefined)?.demoAttributes) return [fakePricingOption]

    return [
      ...onlineStores.map(({ id, name, currency }) => ({ label: `${name} (${currency})`, value: id })),
      fakePricingOption,
    ]
  }

  const handleProductChange = (productId: string) => {
    const product = productsList!.find(product => product.id === productId)!
    const isDemoProduct = !!(product as DemoProduct).demoAttributes

    setProductId(productId)
    setVersion((product as Product)?.draft ? 'draft' : 'live')
    setLanguage(isDemoProduct ? undefined : language)
    setPricing(isDemoProduct ? 'FAKE_PRICING' : pricing)
  }

  const handlePricingChange = (pricing: string | undefined) => {
    setPricing(pricing)

    if (!pricing) {
      iframeRef.current?.contentWindow?.customizerApp?.store.dispatch(customizerPricingActions.setPricing())
    }
  }

  const handleReset = () => {
    const settings = getThemeConfig(theme!.name).settings
    formik.setValues(themesUtils.reduceSettingToValues(settings))
    resetThemeModal.close()
  }

  const handleNewThemeSelected = () => {
    setSettingSection(undefined)
    const iframe = document.getElementById('preview-iframe') as HTMLIFrameElement | null
    iframe?.contentWindow?.location?.reload()
    queryClient.invalidateQueries(brandService.fetchAccount.queryKeys)
    selectThemeModal.close()
  }

  useEffect(() => {
    invalidateQueries()
  }, [params.themeId])

  useEffect(() => {
    if (isReady && pricing === 'FAKE_PRICING') {
      iframeRef.current?.contentWindow?.customizerApp?.store.dispatch(
        customizerPricingActions.createFakePricing() as any
      )
    }
  }, [isReady, pricing])

  useEffect(() => {
    if (isReady && outOfStockState) {
      iframeRef.current?.contentWindow?.customizerApp?.store.dispatch(customizerStocksActions.createFakeStocks() as any)
    } else if (isReady && !outOfStockState) {
      iframeRef.current?.contentWindow?.customizerApp?.store.dispatch(customizerStocksActions.setStocks({}) as any)
    }
  }, [isReady, outOfStockState])

  useEffect(() => {
    iframeRef.current?.contentWindow?.customizerApp?.store.dispatch(customizerThemeActions.setTheme(formik.values))
  })

  if (
    isLoadingTheme ||
    isLoadingBrand ||
    isLoadingProducts ||
    isLoadingDemos ||
    isLoadingTranslations ||
    isLoadingOnlineStores ||
    !theme ||
    !themeSettings ||
    !settingSection ||
    !selectedProduct
  )
    return (
      <div className="flex flex-col flex-grow h-screen">
        <TopBar className="justify-between items-center px-5">
          <TopBarEnvTag />
          <div className="flex flex-1 items-center">
            <TopBar.BackButton onBackClick={handleBackClick} />
          </div>
        </TopBar>
        <div className="h-full flex flex-col items-center justify-center pb-48">
          <Skater width={40} aria-label="Skater loading" />
        </div>
      </div>
    )

  const languageOptions = translations.map(({ name, code }) => ({ label: name, value: code }))
  const pricingOptions = getPricingOption()

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <div className="flex flex-col flex-grow h-screen">
        <TopBar className="justify-between items-center px-5">
          <TopBarEnvTag />
          <div className="flex flex-1 items-center">
            <TopBar.BackButton onBackClick={handleBackClick} />
            <div className="capitalize ml-2 mr-2">{theme!.name}</div>
            <TopBarThemeActions onThemeChangeClick={selectThemeModal.open} themeId={themeId!} />
          </div>
          <div className="flex justify-center items-center space-x-4">
            <ProductSelectControl
              products={productsList || []}
              selectedProductId={productId}
              className="w-56"
              onChange={option => handleProductChange(option!.value)}
              blurInputOnSelect
              disabled={!isReady}
              isSearchable
              aria-label="Select a product"
            />
          </div>
          <div className="flex flex-1 justify-end items-center space-x-4">
            <PreviewModeControl
              disabled={!isReady}
              selectedMode={previewMode}
              availableModes={[PreviewMode.DESKTOP, PreviewMode.MOBILE]}
              onChange={newMode => setPreviewMode(newMode)}
            />
            <CustomizerOptionsControl
              iframeRef={iframeRef}
              disabled={!isReady}
              selectedVersion={version}
              onVersionChange={version => setVersion(version)}
              hasDraft={!!(selectedProduct as Product | undefined)?.draft}
            >
              <div className="mt-4">
                <Label className="font-medium">Other</Label>

                <div className="flex flex-col space-y-4 mt-3">
                  <Select
                    options={pricingOptions}
                    value={pricingOptions.find(({ value }) => value === pricing) || null}
                    onChange={option => handlePricingChange(option?.value)}
                    placeholder="Select a pricing"
                    blurInputOnSelect
                    isClearable
                    disabled={!isReady}
                    aria-label="Select a pricing"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: provided => ({ ...provided, zIndex: 60 }),
                    }}
                  />
                  {languageOptions.length > 0 && (
                    <Select
                      options={languageOptions}
                      value={languageOptions.find(({ value }) => value === language) || null}
                      onChange={option => setLanguage(option?.value)}
                      placeholder="Select a language"
                      blurInputOnSelect
                      isClearable
                      disabled={!isReady || !!(selectedProduct as DemoProduct | undefined)?.demoAttributes}
                      aria-label="Select a language"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: provided => ({ ...provided, zIndex: 60 }),
                      }}
                    />
                  )}
                  <PreviewOutOfStockSetting setOutOfStockState={setOutOfStockState} outOfStockState={outOfStockState} />
                </div>
              </div>
            </CustomizerOptionsControl>
            <div className="w-[1px] h-[54px] bg-neutral-75 ml-1 mr-3"></div>
            {!isCurrentThemeForBrand && <Tag className="bg-neutral-75 ml-2">Draft</Tag>}
            {!isCurrentThemeForBrand && !theme!.draft && !formik.dirty ? (
              <Button
                type="button"
                onClick={() => selectTheme({ name: theme.name, shouldUpdateBrand: true })}
                variant="green"
              >
                Publish
              </Button>
            ) : (
              <PublishDiscard
                isDirty={!!theme!.draft || formik.dirty}
                isPublished={!!theme!.live && !theme!.draft && !draftCreated}
                isSubmitting={formik.isSubmitting}
                isDiscarding={isDiscarding}
                onPublish={formik.submitForm}
                onDiscard={() => discardTheme(themeId!)}
              />
            )}
          </div>
        </TopBar>
        <div className="flex flex-grow h-full max-h-[calc(100%-54px)] mt-[52px]">
          <ThemeSettingsSectionsPanel
            onReset={resetThemeModal.open}
            settingSection={settingSection}
            setSettingSection={setSettingSection}
            settings={themeSettings}
          />
          <div className="flex flex-col flex-grow relative overflow-x-auto">
            <div className="flex flex-grow bg-neutral-50 relative max-w-full max-h-full py-12 px-2 xl:px-4 2xl:px-8 3xl:px-12">
              <PreviewIframe
                ref={iframeRef}
                src={getIFrameSrc()}
                className="shadow rounded-lg"
                previewMode={previewMode}
                onLoad={() => setIsReady(true)}
                onUnload={() => setIsReady(false)}
              />
            </div>
          </div>

          <FormikProvider value={formik}>
            <ThemeSettingsPanel theme={theme} settingSection={settingSection} settings={themeSettings} />
          </FormikProvider>
        </div>
      </div>

      {selectThemeModal.isVisible && (
        <SelectThemeModal
          onNewThemeSelected={handleNewThemeSelected}
          onClose={selectThemeModal.close}
          {...selectThemeModal.modalProps}
        />
      )}

      {resetThemeModal.isVisible && (
        <Modal {...resetThemeModal.modalProps} onBackdropClick={resetThemeModal.close}>
          <Modal.CloseButton onClick={resetThemeModal.close} />
          <Modal.Title>Reset your theme</Modal.Title>

          <Modal.Details>
            Are you sure you want to restore your theme settings to their original value? The changes will not be
            reflected in the live theme until you publish them.
          </Modal.Details>
          <Modal.Actions>
            <Button type="button" variant="default" className="px-4" onClick={resetThemeModal.close}>
              Cancel
            </Button>
            <Button variant="error" className="px-4" onClick={handleReset} data-testid="reset-modal-btn">
              Reset
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </form>
  )
}

export default ThemeBuilder
