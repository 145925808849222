import { Table } from '@packages/sk8/table'
import { Tooltip } from '@packages/sk8/tooltip'
import React from 'react'

import { FormikVariants, VariantFormValue } from '../types'
import { getInventoryItem } from '../utils'
import useSharedQuantities from './useSharedQuantities'

export interface CommittedCellProps {
  variant: VariantFormValue
  formik: FormikVariants
  locationId: string
}

const CommittedCell = ({ locationId, variant, formik }: CommittedCellProps) => {
  const committedData = useSharedQuantities(locationId, variant, formik)

  const { stock, sku } = getInventoryItem(formik, variant)

  if (stock == null) return <Table.EmptyCellIndicator />

  return (
    <div className="flex">
      <Tooltip
        content={
          <div>
            <div>
              <span className="font-medium">{committedData.committed}</span> committed for{' '}
              <span className="font-medium">{variant.name}</span>
            </div>
            <div>
              <span className="font-medium">{committedData.totalCommitted}</span> committed for SKU{' '}
              <span className="font-medium">{sku}</span>
            </div>
          </div>
        }
      >
        <span>{committedData.committed}</span>
        {committedData.isShared && committedData.totalCommitted > 0 && (
          <span className="text-neutral-300 ml-1">of {committedData.totalCommitted}</span>
        )}
      </Tooltip>
    </div>
  )
}

export default CommittedCell
