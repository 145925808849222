import { CheckboxFilter } from '@packages/sk8/filter'
import { Product } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import useProductService from 'common/products/hooks/useProductService'

type DesignsProductFilterProps = {
  filteredProducts: string[] | undefined
  toggleProducts: (name: string) => void
  clearFilters: () => void
}

const DesignsProductFilter = ({ filteredProducts, toggleProducts, clearFilters }: DesignsProductFilterProps) => {
  const productService = useProductService()
  const { data: products } = useQuery(productService.fetchAll.queryKeys, () => productService.fetchAll(), {
    select: data => (Array.isArray(data) ? (data as Product[]) : data?.results),
  })

  const isEmpty = !products?.length
  if (isEmpty) return null
  return (
    <CheckboxFilter
      searchable
      name="Products"
      filters={products?.map(product => ({ name: product.id, text: product.name })) || []}
      activeFilters={filteredProducts || []}
      toggleFilter={toggleProducts}
      clearFilter={clearFilters}
    />
  )
}

export default DesignsProductFilter
