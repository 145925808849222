import { Button } from '@packages/sk8/button'
import { SearchFilter, SortField, SortFilter } from '@packages/sk8/filter'
import { isEmpty } from 'lodash'
import React from 'react'
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params'

import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'

const SORT_FIELDS: SortField[] = [
  {
    name: 'updatedAt',
    text: 'Dates',
    type: 'date',
    defaultOrder: -1,
  },
  {
    name: 'name',
    text: 'Product name',
    type: 'alpha',
    defaultOrder: 1,
  },
]

export const SortKeyParam = withDefault(StringParam, 'updatedAt')
export const SortOrderParam = withDefault(NumberParam, -1)

const ProductsFilters = () => {
  const [search, setSearch] = useQueryParam('search', StringParam, { updateType: 'replaceIn' })
  const [sortKey, setSortKey] = useQueryParam('sortKey', SortKeyParam, { updateType: 'replaceIn' })
  const [sortOrder, setSortOrder] = useQueryParam('sortOrder', SortOrderParam, { updateType: 'replaceIn' })

  return (
    <div className="flex items-center py-4 border-b border-neutral-100 mb-4">
      <div className="flex items-center flex-wrap">
        <div className="mr-2">
          <SearchFilter
            name="Product"
            aria-label="search by product name"
            setSearch={setSearch}
            search={search || ''}
          />
        </div>
      </div>

      <div className="flex items-center flex-wrap">
        <div className="mr-2">
          <SortFilter
            name="Sort"
            setSortKey={setSortKey}
            sortKey={sortKey}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            fields={SORT_FIELDS}
          />
        </div>
      </div>

      <div className="ml-auto">
        <Button
          variant="text"
          onClick={() => {
            setSearch(null)
            setSortKey('updatedAt')
            setSortOrder(-1)
          }}
          icon={<Remove className="w-2.5 h-2.5 fill-current" />}
          disabled={isEmpty(search) && sortKey === 'updatedAt' && sortOrder === -1}
        >
          Clear filters
        </Button>
      </div>
    </div>
  )
}

export default ProductsFilters
